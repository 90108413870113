<template>
  <div :span="24" style="height:400px;" ref="tabs" class="nav_box" @mouseleave="hide()">
    <ul class="left_nav">
      <li v-for="(item,index) in list" :key="index"  :class="[index===currentIndex?'active':'']"   @click="changeIndex(index)" @mouseenter="show(index,item)">
        <p  @click="jumpClassify(item)"><span>{{ item.name }}</span><span><i class="el-icon-arrow-right" aria-hidden="true"></i></span></p>
        <!-- <p>
          <span v-for="(secItem,secIndex) in (item.child||'').slice(0 , 2)" :key="secIndex" :title="secItem.name">{{secItem.name}} </span>
        </p> -->
      </li>
    </ul>
    <div
      class="nav_menu"
      v-for="(item,index) in list"
      :key="index"  
      v-show="index === currentIndex"
    >
      <NavMenu :navmenu="datas" @hideFun="hideDivFun"></NavMenu>
    </div>
  </div>
</template>
<script>
import {ProductcategoryTree} from 'api/product.js'
import {getLocalToken } from "utils/session.js";
const NavMenu = ()=>import("components/index/navMenu.vue");
export default {
  name: "leftNav",
  data() {
    return {
      datas:'',
      list:'',
      currentIndex: -1,
    };
  },
  computed: {
    // filteredItems: function (data) {
    //   return data
    // }
  },
  mounted(){
  },
  methods: {
    jumpClassify(item) {
      console.log(item)
      const newTooltip=[];
      localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      localStorage.setItem('clickVal',item.name);
      localStorage.setItem('clickID',item.id);
      this.$router.push({path: "/productcategory", query: { ids: item.id } });
      this.$emit("DivhideFun",false)
    },
    hideDivFun(){
      this.$emit("DivhideFun",false)
    },
    changeIndex(index) {
      this.currentIndex = index;
    },
    show(index,item) {
      this.currentIndex = index;
      this.datas=item;
    },
    hide(){
      this.currentIndex =-1;
    },
    
  },
  components: {
    NavMenu,
  },
  created() {
      if(getLocalToken()!=null){
        // 分类
         ProductcategoryTree().then(res=>{
           this.list=res.data.data.child;
           console.log('fenlei',this.list)
        })
      }
     
  },
};
</script>

<style scoped lang="less">
.nav_box{
      overflow-y: scroll;
    overflow-x: hidden;
}
.el-aside{
  overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    flex-shrink: 0;
    background: #3d3838;
}
::-webkit-scrollbar  
{  
    width: 5px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
.nav_box {
  // padding:5px 0 !important;
  background: #3D3838;
  ul {
    float: left;
    height: 100%;
    width: 210px;
    li {
      height:43px;
      padding:6px 20px;
            &:hover{
                color: #FFD303;
            }
      p {
        height:46px;
        line-height:46px;
      }
      p:nth-child(1){
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        span:nth-child(1){
          float: left;
        }
         span:nth-child(2){
          float: right; 
        }
      }
       p:nth-child(2){
        color:#FFFFFF;
         font-size: 12px;
         text-align:left;
        span{
          width:72px;
          display: inline-block;
          white-space:nowrap;
          text-overflow:ellipsis;
          overflow:hidden;
           padding-right:10px;
        }
      }
    }
    li:hover{
      background: #665D5D;
    }
  }
  .nav_menu {
    position: absolute;
    height: 480px;
    overflow-y:scroll;
    background: #fff;
    width: 500px;
    left: 210px;
    top: 0;
    z-index: 999;
    padding: 20px 40px;
    box-shadow: 0 0 15px #0000001A;
  }
}
</style>
